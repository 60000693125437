<template>
  <div class="wrap-steps">
    <p style="font-size:18px;">建设项目登记</p>

    <el-form
      :model="formData"
      class="demo-form-inline"
      label-position="top"
      label-width="100px"
    >
      <el-row style="width: 100%" :gutter="20">
        <el-form-item class="theSteps">
          <el-steps :active="active" finish-status="success">
            <el-step
              :title="task.taskName"
              v-for="task in taskSteps"
              :key="task.taskId"
              :class="stepActive"
            >
            </el-step>
          </el-steps>
          <el-button style="margin-top: 12px" @click="next">下一步</el-button>
        </el-form-item>
        <el-col :sm="24" :lg="12">
          <el-form-item label="地块编号" class="wrap-input" :required="true">
            <el-input
              v-model="formData.Id"
              placeholder="请输入地块编号 ..."
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :lg="12">
          <el-form-item label="项目名称" class="wrap-input">
            <el-input
              v-model="formData.ProjectName"
              placeholder="请输入项目名称 ..."
            ></el-input>
          </el-form-item>
        </el-col>
        <el-form-item class="wrap-btn">
          <el-button type="primary" @click="onSubmit">匹配</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// import { Edit, Upload, Picture } from '@element-plus/icons'
export default {
  data() {
    return {
      active: 0,
      taskSteps: [
        { taskName: "步骤1", taskId: 1, icon: "el-icon-edit-outline" },
        { taskName: "步骤2", taskId: 2, icon: "el-icon-upload" },
        { taskName: "步骤3", taskId: 3, icon: "el-icon-picture" },
      ],
      stepActive: "stepActive", // 动态添加类名
      formData: {
        Id: "",
        ProjectName: "",
      },
    };
  },

  methods: {
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    onSubmit() {
      console.log("submit!",this.formData);
    },
  },
  components: {
    //   Edit,Upload, Picture
  },
};
</script>
<style scoped>
.wrap-btn{
  width: 100%;
}
.wrap-btn .el-button{
  float: right;
  margin-right: 10px;
}
.wrap-steps{
  background: #ffffff;
}
.wrap-steps :deep( .el-form--label-top .el-form-item__label) {
  /* display: inline-block;
    max-width: 100%;
    margin-bottom: 5px; */
  font-weight: 700;
  color: #000000;
  padding: 0px;
}
.el-row {
  margin-left: 0 !important;
}
.wrap-steps .theSteps {
  /* border: 1px solid red; */
  width: 60%;
  padding-top: 20px;
  display: block;
}
/* .wrap-input{
  width: 100%;
} */
/* 进行中状态：圈线 */
.theSteps :deep( .el-step__head.is-process) {
  color: #efa73b;
  border-color: #efa73b;
}
/* 进行中状态：圈内 */
.theSteps :deep( .el-step__head.is-process > .el-step__icon) {
  background: #efa73b;
  color: #fff;
}
/* 进行中状态：title（文字） */
.theSteps :deep( .el-step__title.is-process) {
  color: #efa73b;
}

/* 完成状态：圈线 */
.theSteps :deep( .el-step__head.is-success) {
  color: #efa73b;
  border-color: #efa73b;
}
/* 完成状态：title（文字） */
.theSteps :deep( .el-step__title.is-success) {
  color: #efa73b;
}
/* 完成状态：line
 * 描述：第一步完成，第二步进行时，之间的进度条有颜色
 */
.theSteps
  :deep( .el-step__head.is-success
  > .el-step__line
  > .el-step__line-inner) {
  width: 100% !important;
  border-width: 1px !important;
}
</style>